<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col>
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          color="primary"
        />
      </v-col>
    </v-row>
    <sequential-entrance from-top>
      <v-row>
        <v-col
          sm="12"
          md="12"
          lg="12"
        >
          <base-material-card
            color="primary"
            icon="mdi-group"
            inline
          >
            <template v-slot:after-heading>
              <div class="text-h3 font-weight-light">
                Application Questions Management
              </div>
            </template>
            <v-data-table
              :headers="headers"
              :items="getqs"
              :search="search"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-toolbar-title>Questions</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  />
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  />
                  <v-spacer />
                  <v-dialog
                    v-model="dialog"
                    max-width="500px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        New Question
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-text-field
                                v-model="editedItem.label"
                                label="Question Label"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-text-field
                                v-model="editedItem.question"
                                label="Question"
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="close"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="save"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog
                    v-model="dialogDelete"
                    max-width="600px"
                  >
                    <v-card>
                      <v-card-title class="text-h5">
                        Are you sure you want to delete this item?
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="closeDelete"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItemConfirm"
                        >
                          OK
                        </v-btn>
                        <v-spacer />
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initialize"
                >
                  Reset
                </v-btn>
              </template>
            </v-data-table>
          </base-material-card>
        </v-col>
      </v-row>
    </sequential-entrance>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      search: '',
      headers: [
        {
          text: 'Label',
          align: 'left',
          sortable: true,
          value: 'label',
          width: 150,
        },
        {
          text: 'Question',
          align: 'left',
          sortable: true,
          value: 'question',
        },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false },
      ],
      questions: [],
      editedIndex: -1,
      editedItem: {
        label: '',
        question: '',
        key: '',
      },
      defaultItem: {
        label: '',
        question: '',
        key: '',
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Question' : 'Edit Question'
      },
      loading () {
        // return true
        return this.$store.getters.getloading
      },
      getqs () {
        this.questions = []
        this.questions = this.$store.getters.getAnicoAppQuestions
        console.log('out qs are ', this.questions)
        return this.questions
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.rels = []
      this.$store.dispatch('loadAnicoAppQuestions')
      this.initialize()
    },

    methods: {
      initialize () {
        this.questions = this.$store.getters.getAnicoAppQuestions
      },

      editItem (item) {
        this.editedIndex = this.questions.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.questions.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.questions.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        console.log('index is', this.editedIndex)
        console.log(this.editedItem)
        console.log(' q array is ', this.questions)
        if (!this.questions) {
          this.questions = []
        }
        // we can only edit ones with a key value
        if (this.editedIndex > -1) {
          Object.assign(this.questions[this.editedIndex], this.editedItem)
          this.$store.dispatch('updateAnicoAppQuestion', this.editedItem)
        } else {
          console.log('make a new one ', this.editedItem)
          this.questions.push(this.editedItem)
          this.$store.dispatch('createAnicoAppQuestion', this.editedItem)
        }
        this.$store.dispatch('loadAnicoAppQuestions')
        this.close()
      },
    },
  }
</script>
